<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14 nav-selected">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-32">
          <span class="fm-16" style="color: #515466">아이디 비밀번호 등록</span>
        </div>
        <div class="d-flex justify-content-center column">
          <div class="warning mb-16 d-flex fr-14 f-g60">
            <div class="triangle d-flex justify-content-center mr-8 fb-12">
              !
            </div>
            가입승인 소식을 받으실 이메일을 아이디로 설정해 주세요
          </div>
          <div class="login-id pb-16">
            <div class="d-flex align-items-center justify-content-center">
              <input
                type="text"
                class="input-full-width mr-8"
                placeholder="로그인ID (이메일 형식으로 입력하세요.)"
                v-model="email"
                @change="isEmailCheck = false"
              />
              <button-common
                :size="SIZE_T"
                :clr="CLR_G"
                :disabled="!checkEmailReg"
                style="min-width: 160px"
                @click="doEmailCheck"
                >아이디 중복확인</button-common
              >
            </div>
            <div class="error" style="margin-top: 22px" v-if="!checkEmailReg">
              이메일 형식으로 작성해주세요.
            </div>
            <div
              class="error"
              style="margin-top: 22px"
              v-if="
                checkEmailReg &&
                !isFirst &&
                email &&
                !isEmailCheck &&
                !isDuplicate
              "
            >
              이메일을 현재 자주 사용하시는 주소로 설정하셨나요?
            </div>
            <div
              class="error"
              style="margin-top: 22px"
              v-if="
                checkEmailReg &&
                !isFirst &&
                email &&
                !isEmailCheck &&
                isDuplicate
              "
            >
              이미 등록된 아이디 입니다. 다른 아이디를 입력해 주세요
            </div>
            <div
              class="confirm"
              style="margin-top: 22px"
              v-if="checkEmailReg && !isFirst && email && isEmailCheck"
            >
              사용가능한 아이디 입니다.
            </div>
          </div>

          <input
            type="password"
            class="input-full-width mb-16 mt-16"
            placeholder="영어, 특수문자를 포함하여 8~16자 이내의 비밀번호를 작성해 주세요."
            v-model="password"
          />
          <div class="error mb-16" v-if="!checkPasswordReg">
            영어, 숫자, 특수문자를 포함해서 8~16자 이내로 입력해주세요
          </div>
          <input
            type="password"
            class="input-full-width"
            placeholder="비밀번호를 한번 더 기재해 주세요"
            v-model="passwordCheck"
          />
          <div class="error mt-16" v-if="password !== passwordCheck">
            비밀번호가 일치하지 않습니다.
          </div>
          <div
            class="confirm mt-16"
            v-if="password === passwordCheck && password"
          >
            비밀번호가 일치 합니다
          </div>
        </div>
      </div>
    </div>
    <div class="check" style="margin-top: 124px">
      이메일을 현재 자주 사용하시는 주소로 설정하셨나요?
    </div>
    <div
      class="
        d-flex
        align-items-center
        checkbox-wrap
        mt-16
        justify-content-center
      "
    >
      <input
        type="checkbox"
        id="term"
        name="term"
        value="term"
        v-model="isAgree"
      />
      <label for="term" class="label-relative">
        <span class="fr-14 f-g70">네</span>
      </label>
    </div>
    <div class="next mb-80" style="margin-top: 130px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="
          !isAgree ||
          password !== passwordCheck ||
          !checkEmailReg ||
          !isEmailCheck ||
          !checkPasswordReg
        "
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regAccount()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Email",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      passwordCheck: "",
      isFirst: true,
      isEmailCheck: true,
      isAgree: false,
      isDuplicate: false,
    };
  },
  computed: {
    checkEmailReg() {
      const regex =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
      return regex.test(this.email);
    },
    checkPasswordReg() {
      const regex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/;
      return regex.test(this.password);
    },
  },
  methods: {
    async doEmailCheck() {
      if (this.email) {
        const rs = await this.$axios({
          url: "/partner/reg/check/email",
          params: { email: this.email },
        });
        this.isFirst = false;
        this.isEmailCheck = rs.result === "SUC";
        this.isDuplicate = rs.result === "DUPL";
      }
    },
    async regAccount() {
      const rs = await this.$axios({
        url: "/partner/reg/id",
        params: {
          email: this.email,
          pass: this.password,
          rePass: this.password,
        },
      });
      if (rs.result === "SUC") {
        this.goNextPage();
      }
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/address`,
        meta: { id: "0-1-5" },
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";

.error {
  color: #f1431d;
  font-size: 14px;
}
.confirm {
  color: #2ec8b5;
  font-size: 14px;
}
</style>
